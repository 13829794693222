import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import * as ROUTES from './constants/routes'
// import withAuthentication from './components/Auth/withAuthentication';
import lazyComponent from './components/LazyComponent'

const LoginPage = lazyComponent(()=> import("./app/login"), { loadingFullScreen: true })
const HomePage = lazyComponent(()=> import('./app/home'), { loadingFullScreen: true })
const NotFoundPage = lazyComponent(()=> import('./app/not-found'), { loadingFullScreen: true })

const App = () => (
  <Router>
    <Switch>
      <Route path={ROUTES.HOME} component={HomePage} />
      <Route path={ROUTES.LOGIN} exact component={LoginPage} />
      <Redirect path={ROUTES.LANDING} to={ROUTES.DASHBOARD} />
      <Route component={NotFoundPage} />
    </Switch>
  </Router>
)

export default App;
