import React from "react";
import ReactQuill, { Quill } from "react-quill";
import { ImageUpload }  from 'quill-image-upload';
import "./Editor.less";
import getToken from "../../utils/getToken";

Quill.register('modules/imageUpload', ImageUpload);

class Editor extends React.Component {
  constructor(props) {
    super(props);
    this.state = { editorHtml: this.props.value };
  }

  handleChange = html => {
    this.setState({ editorHtml: html });
    this.props.onChange(html);
  };

  modules = {
    toolbar: [
      [{ font: [] }, { size: [] }],
      [{ align: [] }, 'direction' ],
      [ 'bold', 'italic', 'underline', 'strike' ],
      [{ color: [] }, { background: [] }],
      [{ script: 'super' }, { script: 'sub' }],
      ['blockquote', 'code-block' ],
      [{ list: 'ordered' }, { list: 'bullet'}, { indent: '-1' }, { indent: '+1' }],
      [ 'link', 'image', 'video' ],
      [ 'clean' ]
    ],
    imageUpload: {
      url: 'https://files.giaodich24h.com/image', // server url. If the url is empty then the base64 returns
      method: 'POST', // change query method, default 'POST'
      name: 'image', // custom form name
      withCredentials: false, // withCredentials
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      // personalize successful callback and call next function to insert new url to the editor
      callbackOK: (serverResponse, next) => {
        next(serverResponse.fileURL);
      },
      // personalize failed callback
      callbackKO: serverError => {
        alert(serverError);
      },
      // optional
      // add callback when a image have been chosen
      checkBeforeSend: (file, next) => {
        console.log(file);
        next(file); // go back to component and send to the server
      }
    },
  }

  render() {
    // console.log(this.props.value, "this.props.value");
    // console.log(this.props)
    return (
      <div className="Editor">
        <ReactQuill
          onChange={this.handleChange}
          placeholder={this.props.placeholder}
          modules={this.modules}
          defaultValue={this.props.defaultValue === undefined ? "" : this.props.defaultValue}
        />
      </div>
    );
  }
}

/*
 * Quill modules to attach to editor
 * See http://quilljs.com/docs/modules/ for complete options
 */
Editor.modules = {
  toolbar: {
    // container: "#toolbar",
    // handlers: {
    //   "insertStar": insertStar,
    // }
  }
};

export default Editor;
