export const GET_TRANSACTIONS_REQUEST = 'GET_TRANSACTIONS_REQUEST'
export const GET_TRANSACTIONS_SUCCESS = 'GET_TRANSACTIONS_SUCCESS'
export const GET_TRANSACTIONS_FAILURE = 'GET_TRANSACTIONS_FAILURE'

export const REMOVE_TRANSACTION_REQUEST = 'REMOVE_TRANSACTION_REQUEST'
export const REMOVE_TRANSACTION_SUCCESS = 'REMOVE_TRANSACTION_SUCCESS'
export const REMOVE_TRANSACTION_FAILURE = 'REMOVE_TRANSACTION_FAILURE'

export const SEARCH_TRANSACTION_REQUEST = 'SEARCH_TRANSACTION_REQUEST'
export const SEARCH_TRANSACTION_SUCCESS = 'SEARCH_TRANSACTION_SUCCESS'
export const SEARCH_TRANSACTION_FAILURE = 'SEARCH_TRANSACTION_FAILURE'
