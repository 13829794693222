import * as actionsType from './Config.ActionType'

const initState = {
  data: null,
  loading: false,
  error: null,
}

export default function configReducer(state = initState, action) {
  switch (action.type) {
    case actionsType.GET_ALL_CONFIG_REQUEST || actionsType.EDIT_CONFIG_REQUEST || actionsType.CREATE_CONFIG_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        data: null
      }
    case actionsType.SEARCH_CONFIG_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        data: null
      }
    case actionsType.SEARCH_CONFIG_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      }
    case actionsType.GET_ALL_CONFIG_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      }
    case actionsType.EDIT_CONFIG_FAILURE || actionsType.GET_ALL_CONFIG_FAILURE || actionsType.CREATE_CONFIG_FAILURE || actionsType.SEARCH_CONFIG_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      }
      default:
        return state
    }
}