export const LANDING = '/';
export const REGISTER = '/register';
export const LOGIN = '/login';
export const HOME = '/home'
export const TRANSACTION = '/home/transaction'
export const SYSTEM = '/home/system'
export const DASHBOARD = '/home/dashboard'
export const USERS = '/home/users'
export const NEWS = '/home/news'
export const NEWS_ADD_NEW = '/home/news/add'
export const NEWS_EDIT = '/home/news/edit'
export const USER_EDIT = '/home/users/edit'
export const USER_ADD = '/home/users/add'

export const CONFIGS = '/home/config'

export const TRADE = '/home/trade'
export const TRADE_ADD_NEW = '/home/trade/add'
export const TRADE_EDIT = '/home/trade'

export const CURRENCY = '/home/currency'
export const CURRENCY_ADD_NEW = '/home/currency/add'
export const CURRENCY_EDIT = '/home/currency'
export const FEEDBACK = '/home/feedbacks'
export const FILES = '/home/files'
export const MAILBOX = '/home/mailbox'