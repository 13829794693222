import * as actionTypes from './Feedback.ActionType'
import request from '../../../utils/request';

export const getFeedbackRequest = () => ({
  type: actionTypes.GET_FEEDBACK_REQUEST
})

export const getFeedbackSuccess = (data) => ({
  type: actionTypes.GET_FEEDBACK_SUCCESS,
  data
})

export const getFeedbackFailure = (error) => ({
  type: actionTypes.GET_FEEDBACK_FAILURE,
  error
})


export const editFeedbackRequest = () => ({
  type: actionTypes.EDIT_FEEDBACK_REQUEST
})

export const editFeedbackSuccess = () => ({
  type: actionTypes.EDIT_FEEDBACK_SUCCESS
})

export const editFeedbackFailure = (error) => ({
  type: actionTypes.EDIT_FEEDBACK_FAILURE,
  error
})

export const getFeedbacks = () => {
  return async (dispatch) => {
    dispatch(getFeedbackRequest())
    try {
      const feedbacks = await request({
        url: `/transaction/comment`,
        method: "GET"
      })
      dispatch(getFeedbackSuccess(feedbacks))
    } catch(error) {
      dispatch(getFeedbackFailure(error))
    }
  }
}

export const editFeedback = (id, customerCommentStatus) => {
  return async (dispatch) => {
    dispatch(editFeedbackRequest())
    try {
      await request({
        url: `/transaction/comment/${id}`,
        method: "PUT",
        data: { customerCommentStatus }
      })
      dispatch(editFeedbackSuccess())
    } catch(error) {
      dispatch(editFeedbackFailure(error))
    }
  }
}