import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { authReducer } from './components/Auth';
import { loginReducer } from './app/login';
import { transactionReducer } from './app/home/Transaction';
import { userReducer } from './app/home/User';
import { newsReducer } from './app/home/News';
import configReducer from './app/home/Config/Config.Reducer'

import { tradeReducer } from './app/home/Trade';
import { currencyReducer } from './app/home/Currency';
import { feedbackReducer } from './app/home/Feedback'

import thunk from 'redux-thunk';

export const store = createStore(
  combineReducers({
    authReducer,
    loginReducer,
    transactionReducer,
    userReducer,
    newsReducer,
    configReducer,
    tradeReducer,
    currencyReducer,
    feedbackReducer
  }),
  compose(
    applyMiddleware(thunk),
    process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f,
  ),
);
