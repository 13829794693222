import * as actionTypes from './Transaction.ActionType'
import request from '../../../utils/request';

export const getTransactionsRequest = () => ({
  type: actionTypes.GET_TRANSACTIONS_REQUEST
})

export const getTransactionsSuccess = (data) => ({
  type: actionTypes.GET_TRANSACTIONS_SUCCESS,
  data
})

export const getTransactionsFailure = (error) => ({
  type: actionTypes.GET_TRANSACTIONS_FAILURE,
  error
})

export const removeTransactionRequest = () => ({
  type: actionTypes.REMOVE_TRANSACTION_REQUEST
})

export const removeTransactionSuccess = (data) => ({
  type: actionTypes.REMOVE_TRANSACTION_SUCCESS,
  data
})

export const removeTransactionFailure = (error) => ({
  type: actionTypes.REMOVE_TRANSACTION_FAILURE,
  error
})

export const searchTransactionRequest = () => ({
  type: actionTypes.SEARCH_TRANSACTION_REQUEST
})

export const searchTransactionSuccess = (data) => ({
  type: actionTypes.SEARCH_TRANSACTION_SUCCESS,
  data
})

export const searchTransactionFailure = (error) => ({
  type: actionTypes.SEARCH_TRANSACTION_FAILURE,
  error
})

export const getTransactions = () => {
  return async (dispatch) => {
    dispatch(getTransactionsRequest())
    try {
      const transactions = await request({
        url: "transaction",
        method: "GET"
      })
      dispatch(getTransactionsSuccess(transactions))
    } catch(error) {
      dispatch(getTransactionsFailure(error))
    }
  }
}

export const removeTransaction = (id) => {
  return async (dispatch) => {
    dispatch(removeTransactionRequest())
    try {
      dispatch(removeTransactionSuccess([]))
    } catch(error) {
      dispatch(removeTransactionFailure(error))
    }
  }
}

export const searchTransaction = (id) => {
  return async (dispatch) => {
    dispatch(searchTransactionRequest())
    try {
      const transactions = await request({
        url: `transaction/search/${id}`,
        method: "GET",
      })
      dispatch(searchTransactionSuccess([transactions]))
    } catch(error) {
      dispatch(searchTransactionFailure(error))
    }
  }
}

export const exportTransactionFile = (fromDate, toDate) => {
  return () => {
    window.open(`https://api.giaodich24h.com/transaction/download/?fromDate=${fromDate}&toDate=${toDate}`)
  }
}