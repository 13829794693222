export const GET_NEWS_REQUEST = "GET_NEWS_REQUEST";
export const GET_NEWS_SUCCESS = "GET_NEWS_SUCCESS";
export const GET_NEWS_FAIL = "GET_NEWS_FAIL"

export const GET_ONE_NEW_REQUEST = "GET_ONE_NEW_REQUEST";
export const GET_ONE_NEW_SUCCESS = "GET_ONE_NEW_SUCCESS";
export const GET_ONE_NEW_FAIL = "GET_ONE_NEW_FAIL";

export const CREATE_NEWS_REQUEST = "CREATE_NEWS_REQUEST";
export const CREATE_NEWS_SUCCESS = "CREATE_NEWS_SUCCESS";
export const CREATE_NEWS_FAIL = "CREATE_NEWS_FAIL";

export const REMOVE_NEWS_REQUEST = "REMOVE_NEWS_REQUEST";
export const REMOVE_NEWS_SUCCESS = "REMOVE_NEWS_SUCCESS";
export const REMOVE_NEWS_FAIL = "REMOVE_NEWS_FAIL";

export const VIEW_NEWS_REQUEST = "VIEW_NEWS_REQUEST";
export const VIEW_NEWS_SUCCESS = "VIEW_NEWS_SUCCESS";
export const VIEW_NEWS_FAIL = "VIEW_NEWS_FAIL";

export const UPDATE_NEWS_REQUEST = "UPDATE_NEWS_REQUEST";
export const UPDATE_NEWS_SUCCESS = "UPDATE_NEWS_SUCCESS";
export const UPDATE_NEWS_FAIL = "UPDATE_NEWS_FAIL";

export const SEARCH_NEWS_REQUEST = "SEARCH_NEWS_REQUEST";
export const SEARCH_NEWS_SUCCESS = "SEARCH_NEWS_SUCCESS";
export const SEARCH_NEWS_FAIL = "SEARCH_NEWS_FAIL";

export const INITIALIZATION_NEWS_REQUEST = "INITIALIZATION_NEWS_REQUEST";
