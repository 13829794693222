import * as actionTypes from './Currency.ActionType'
import request from '../../../utils/request';

export const createCurrency = (data) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.CREATE_CURRENCY.REQUEST
    })

    try {
      const result = await request({
        url: `currency`,
        method: "POST",
        data
      })

      dispatch({
        type: actionTypes.CREATE_CURRENCY.SUCCESS,
        data: result
      })
    } catch(error) {
      dispatch({
        type: actionTypes.CREATE_CURRENCY.FAILURE,
        error
      })
    }
  }
}

export const getCurrencies = (params) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.GET_CURRENCIES.REQUEST
    })

    try {
      const result = await request({
        url: `currency`,
        method: "GET",
        params
      })

      dispatch({
        type: actionTypes.GET_CURRENCIES.SUCCESS,
        data: result
      })
    } catch(error) {
      dispatch({
        type: actionTypes.GET_CURRENCIES.FAILURE,
        error
      })
    }
  }
}

export const getCurrency = (id) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.GET_CURRENCY.REQUEST
    })

    try {
      const result = await request({
        url: `currency/${id}`,
        method: "GET"
      })

      dispatch({
        type: actionTypes.GET_CURRENCY.SUCCESS,
        data: result 
      })
    } catch(error) {
      dispatch({
        type: actionTypes.GET_CURRENCY.FAILURE,
        error
      })
    }
  }
}

export const updateCurrency = (id, data) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_CURRENCY.REQUEST
    })

    try {
      const result = await request({
        url: `currency/${id}`,
        method: "PUT",
        data
      })

      dispatch({
        type: actionTypes.UPDATE_CURRENCY.SUCCESS,
        data: result
      })
    } catch(error) {
      dispatch({
        type: actionTypes.UPDATE_CURRENCY.FAILURE,
        error
      })
    }
  }
}

export const deleteCurrency = (id) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_CURRENCY.REQUEST
    })

    try {
      await request({
        url: `currency/${id}`,
        method: "DELETE"
      })

      dispatch({
        type: actionTypes.DELETE_CURRENCY.SUCCESS
      })
    } catch(error) {
      dispatch({
        type: actionTypes.DELETE_CURRENCY.FAILURE,
        error
      })
    }
  }
}

export const searchCurrency = (text) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.SEARCH_CURRENCY.REQUEST
    })

    try {
      const result = await request({
        url: `currency/search`,
        method: "POST",
        data: { text }
      })

      dispatch({
        type: actionTypes.SEARCH_CURRENCY.SUCCESS,
        data: result
      })
    } catch(error) {
      dispatch({
        type: actionTypes.SEARCH_CURRENCY.FAILURE,
        error
      })
    }
  }
}