import React, { useEffect, useState } from 'react'
import { Alert, Button, notification } from 'antd';
import { LoginForm } from './components/LoginForm'
import { HOME } from '../../constants/routes'
import ReCAPTCHA from "react-google-recaptcha";
import { RECAPTCHA_KEY } from '../../constants/env';
import OtpInput from 'react-otp-input';
import request from '../../utils/request'

const Login = ({ loginUser, validation, loading, error, history }) => {
  const recaptchaRef = React.createRef();
  const [nextToken, setNextToken] = useState(null)
  const [OTPCode, setOTPCode] = useState("")
  const [recaptchaVal, setRecaptchaVal] = useState(null)

  const handleSubmit  = ({ validateFieldsAndScroll }) => {
    const recaptchaValue = recaptchaRef.current.getValue();
    setRecaptchaVal(recaptchaValue)

    if(!recaptchaValue) {
      notification.error({
        message: `Vui lòng xác nhận Recaptcha: I'm not robot`
      })
      return 
    }

    validateFieldsAndScroll(async (errors, values) => {
      if (errors) {
        return
      }
      try {
        const result = await loginUser({...values, 'g-recaptcha-response': recaptchaValue })
        if(result && result.nextToken) {
          setNextToken(result.nextToken)
        } else {
          window.location.href = HOME
        }
        
      } catch(error) {
        window.grecaptcha.reset();
      }
    })  
  }

  const handle2FA = async () => {
    try {
      await loginUser({ authCode: OTPCode, nextToken, 'g-recaptcha-response': recaptchaVal })
      window.location.href = HOME
    } catch(error) {
      // notification.error({
      //   message: error.message
      // })
    }
  }

  return (
    <div className="login-form">
      <div className="login-form__logo">
        {/* <img alt="logo" src={config.logoPath} /> */}
        <span>BACK OFFICE</span>
      </div>
      <div className="login-form__error">
        { error && 
          <Alert
            message={error.message}
            type="error"
            showIcon
          />
        }
      </div>
      {
        nextToken ?
        <div>
          <OtpInput
            id="num-inputs"
            name="numInputs"
            type="number"
            onChange={setOTPCode}
            min="0"
            inputStyle={{ width: '60px', height: '50px', margin: '10px 5px', fontWeight: 'bold' }}
            value={OTPCode}
            numInputs={6}
            shouldAutoFocus
          />
          <Button type="primary" onClick={handle2FA}>Gửi mã xác nhận</Button>
          
        </div>
        : <>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={RECAPTCHA_KEY}
            // onChange={onChangeRecaptcha}
          />
          <LoginForm
            handleSubmit={handleSubmit}
            validation={validation}
            loading={loading}
          />

        </>
      }
      

      
    </div>
  )
}

export default Login