import { notification } from 'antd';

export default function Toast(message, description, isSuccess = false) {
  return isSuccess
    ? notification.success({
        message,
        description,
      })
    : notification.error({
        message,
        description,
      });
}
