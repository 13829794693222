import * as actionTypes from './User.ActionType'
import request from '../../../utils/request';

export const getUsersRequest = () => ({
  type: actionTypes.GET_USERS_REQUEST
})

export const getUsersSuccess = (data) => ({
  type: actionTypes.GET_USERS_SUCCESS,
  data
})

export const getUsersFailure = (error) => ({
  type: actionTypes.GET_USERS_FAILURE,
  error
})

export const removeUserRequest = () => ({
  type: actionTypes.REMOVE_USER_REQUEST
})

export const removeUserSuccess = (data) => ({
  type: actionTypes.REMOVE_USER_SUCCESS,
  data
})

export const removeUserFailure = (error) => ({
  type: actionTypes.REMOVE_USER_FAILURE,
  error
})

export const searchUserRequest = () => ({
  type: actionTypes.SEARCH_USER_REQUEST
})

export const searchUserSuccess = (data) => ({
  type: actionTypes.SEARCH_USER_SUCCESS,
  data
})

export const searchUserFailure = (error) => ({
  type: actionTypes.SEARCH_USER_FAILURE,
  error
})

export const meRequest = () => ({
  type: actionTypes.ME_REQUEST
})

export const meSuccess = (data) => ({
  type: actionTypes.ME_SUCCESS,
  data
})

export const meFailure = (error) => ({
  type: actionTypes.ME_FAILURE,
  error
})

export const getUsers = () => {
  return async (dispatch) => {
    dispatch(getUsersRequest())
    try {
      const users = await request({
        url: "user",
        method: "GET"
      })
      dispatch(getUsersSuccess(users))
    } catch(error) {
      dispatch(getUsersFailure(error))
    }
  }
}

export const removeUser = (id) => {
  return async (dispatch) => {
    dispatch(removeUserRequest())
    try {
      dispatch(removeUserSuccess([]))
    } catch(error) {
      dispatch(removeUserFailure(error))
    }
  }
}

export const searchUser = (params) => {
  return async (dispatch) => {
    dispatch(searchUserRequest())
    try {
      const users = await request({
        url: `user`,
        method: "GET",
        params
      })
      dispatch(searchUserSuccess(users))
    } catch(error) {
      dispatch(searchUserFailure(error))
    }
  }
}

export const updateUserProfile = (id, data) => {
  return async dispatch => {
    dispatch({ type: actionTypes.UPDATE_USER_PROFILE_REQUEST });

    try {
      await request({
        method: 'PUT',
        url: `/user/${id}`,
        data,
      });
      dispatch({ type: actionTypes.UPDATE_USER_PROFILE_SUCCESS });
    } catch (error) {
      dispatch({ type: actionTypes.UPDATE_USER_PROFILE_FAIL, error });
    }
  };
};

export const createUser = data => {
  return async dispatch => {
    dispatch({ type: actionTypes.REGISTER_REQUEST });

    try {
      await request({
        method: 'POST',
        url: '/user',
        data,
      })

      dispatch({ type: actionTypes.REGISTER_SUCCESS, data });
    } catch(error) {
      dispatch({ type: actionTypes.REGISTER_FAIL, error });
    }
  };
};

export const me = (data) => {
  return async (dispatch) => {
    dispatch(meRequest())
    try {
      const result = await request({
        url: "user/me",
        method: "GET"
      })

      dispatch(meSuccess(result))
    } catch(error) {
      dispatch(meFailure(error))
    }
  }
}