import { loginUser } from './Login.Action'
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import Login from './Login'

const selectorValidateStatus = (loginReducer) => {
  if(loginReducer.loading) {
    return 'validating'
  }

  if (loginReducer.error) {
    return 'error'
  }
}

const selectorValidation = (loginReducer, condition) => {
  const errorMsg = loginReducer.error && condition.test(loginReducer.error.code) && loginReducer.error.message
  
  return {
    status: selectorValidateStatus(loginReducer),
    error: errorMsg
  }
}


const mapStateToProps = state => {
  return { 
    loading: state.loginReducer.loading,
    data: state.loginReducer.data,
    error: state.loginReducer.error,
    validation: {
      email: selectorValidation(state.loginReducer, /user/i),
      password: selectorValidation(state.loginReducer, /password/i)
    }
  };
};

const mapDispatchToProps = { loginUser }

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(Login);