import React from 'react'
import './Loader.less'
import { Spin } from 'antd';
import cn from 'classnames'

const Loader = ({ fullscreen }) => {
  const loaderClass = cn('loader', {
    'loader--fullscreen': fullscreen
  })
  return (
  <div className={loaderClass}>
    <Spin />
  </div>
  )
}

export default Loader