import * as actionTypes from './Login.ActionType'
import request from '../../utils/request'

export const loginUserRequest = () => ({
  type: actionTypes.LOGIN_USER_REQUEST
})

export const loginUserSuccess = (data) => ({
  type: actionTypes.LOGIN_USER_SUCCESS,
  data
})

export const loginUserFailure = (error) => ({
  type: actionTypes.LOGIN_USER_FAILURE,
  error
})

const logoutUserRequest = () => ({
  type: actionTypes.LOGOUT_USER_REQUEST
})

const logoutUserSuccess = (data) => ({
  type: actionTypes.LOGOUT_USER_SUCCESS,
  data
})

const logoutUserFailure = (error) => ({
  type: actionTypes.LOGOUT_USER_FAILURE,
  error
})

export const loginUser = (data) => {
  return async (dispatch) => {
    dispatch(loginUserRequest())
    try {
      const token = await request({
        url: "user/login",
        method: "POST",
        data
      })

      if(token && token.nextToken) {
        return token
      } else {
        localStorage.setItem("token", token.token)
        return null
      }
    } catch(error) {
      dispatch(loginUserFailure(error))
      throw error
    }
  }
}

export const logoutUser = () => {
  return async (dispatch) => {
    dispatch(logoutUserRequest())
    try {
      await request({
        url: "user/logout",
        method: "POST"
      })
      dispatch(logoutUserSuccess())
    } catch(error) {
      dispatch(logoutUserFailure())
    }

    localStorage.removeItem("token")
    window.location.href = '/'
  }
}