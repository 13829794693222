import * as actionTypes from "./News.ActionType";
import request from "../../../utils/request";

export const initializeNews = () => {
  return async dispatch => {
    dispatch({ type: actionTypes.INITIALIZATION_NEWS_REQUEST });
  };
};

export const getOneNews = id => {
  return async dispatch => {
    dispatch({ type: actionTypes.GET_ONE_NEW_REQUEST });
    try {
      const result = await request({
        url: `news/${id}`,
        method: "GET"
      });

      dispatch({ type: actionTypes.GET_ONE_NEW_SUCCESS, payload: result });
    } catch (err) {
      dispatch({ type: actionTypes.GET_ONE_NEW_FAIL, error: err });
    }
  };
};

export const getAllNews = (params = {}) => {
  return async dispatch => {
    dispatch({ type: actionTypes.GET_NEWS_REQUEST });
    try {
      const result = await request({
        url: "news",
        method: "GET",
        params
      });
      dispatch({ type: actionTypes.GET_NEWS_SUCCESS, payload: result });
    } catch (err) {
      dispatch({ type: actionTypes.GET_NEWS_SUCCESS, error: err });
    }
  };
};

export const createOneNews = news => {
  return async dispatch => {
    dispatch({ type: actionTypes.CREATE_NEWS_REQUEST });
    try {
      const result = await request({
        url: "news",
        data: news,
        method: "POST"
      });
      dispatch({ type: actionTypes.CREATE_NEWS_SUCCESS, payload: result.data });
    } catch (err) {
      dispatch({ type: actionTypes.CREATE_NEWS_FAIL, error: err });
    }
  };
};

export const searchNews = id => {
  return async dispatch => {
    dispatch({ type: actionTypes.SEARCH_NEWS_REQUEST });
    try {
      const result = await request({
        url: `news/${id}`,
        method: "GET"
      });
      dispatch({ type: actionTypes.SEARCH_NEWS_SUCCESS, payload: result.data });
    } catch (err) {
      dispatch({ type: actionTypes.SEARCH_NEWS_FAIL, error: err });
    }
  };
};

export const removeNews = id => {
  return async dispatch => {
    dispatch({ type: actionTypes.REMOVE_NEWS_REQUEST });
    try {
      const result = await request({
        url: `news/${id}`,
        method: "DELETE"
      });
      dispatch({ type: actionTypes.REMOVE_NEWS_SUCCESS, payload: result.data });
      dispatch(getAllNews());
    } catch (err) {
      dispatch({ type: actionTypes.REMOVE_NEWS_FAIL, error: err });
    }
  };
};

export const editNews = (id, data) => {
  return async dispatch => {
    dispatch({ type: actionTypes.UPDATE_NEWS_REQUEST });
    try {
      const result = await request({
        url: `news/${id}`,
        method: "PUT",
        data
      });
      dispatch({ type: actionTypes.UPDATE_NEWS_SUCCESS, payload: result.data });
    } catch (err) {
      dispatch({ type: actionTypes.UPDATE_NEWS_FAIL, error: err });
    }
  };
};
