import * as actionsType from './Feedback.ActionType'

const initState = {
  data: null,
  isComplete: false,
  loading: false,
  error: null,
}

export default function feedbackReducer(state = initState, action) {
  switch (action.type) {
    case actionsType.GET_FEEDBACK_REQUEST:
      return {
        ...state,
        isComplete: false,
        loading: true,
        error: null
      }
    case actionsType.GET_FEEDBACK_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      }
    case actionsType.GET_FEEDBACK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      }

    case actionsType.EDIT_FEEDBACK_REQUEST:
      return {
        ...state,
        loading: true,
        isComplete: false,
        error: null
      }

    case actionsType.EDIT_FEEDBACK_SUCCESS:
      return {
        ...state,
        loading: false
      }

    case actionsType.EDIT_FEEDBACK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    default:
      return state
  }
}