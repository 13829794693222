import React from "react";
import { Tag, Input, Icon } from "antd";
import { TweenOneGroup } from "rc-tween-one";

class EditableTagGroup extends React.Component {
  state = {
    tags: this.props.values,
    inputVisible: false,
    inputValue: ""
  };

  handleClose = removedTag => {
    const tags = this.state.tags.filter(tag => tag !== removedTag);
    console.log(tags);
    this.setState({ tags });
    this.props.onChange(tags)
  };

  showInput = () => {
    this.setState({ inputVisible: true }, () => this.input.focus());
  };

  handleInputChange = e => {
    console.log("handleInputChange", e);
    this.setState({ inputValue: e.target.value });
  };

  handleInputConfirm = () => {
    console.log("handleInputConfirm");
    const { inputValue } = this.state;
    let { tags } = this.state;
    if (inputValue && tags.indexOf(inputValue) === -1) {
      tags = [...tags, inputValue];
    }

    this.setState({
      tags,
      inputVisible: false,
      inputValue: ""
    });

    this.props.onChange(tags);
  };

  saveInputRef = input => (this.input = input);

  forMap = tag => {
    const tagElem = (
      <Tag
        closable
        onClose={e => {
          e.preventDefault();
          this.handleClose(tag);
        }}
      >
        {tag}
      </Tag>
    );
    return (
      <span key={tag} style={{ display: "inline-block" }}>
        {tagElem}
      </span>
    );
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.values !== prevState.tags) {
      return { tags: nextProps.values };
    } else return null;
  }

  // componentDidMount(prevProp) {
  //   console.log("Did mount", this.props);
  //   // if (prevProp.values !== this.props.values)
  //     this.setState({
  //       tags: this.props.values
  //     });
  // }

  render() {
    const { tags, inputVisible, inputValue } = this.state;

    const tagChild = tags.map(this.forMap);
    // console.log(tagChild, tags, "tagChild");
    return (
      <div>
        <div style={{ marginBottom: 16, display: "inline-block" }}>
          <TweenOneGroup
            enter={{
              scale: 0.8,
              opacity: 0,
              type: "from",
              duration: 100,
              onComplete: e => {
                e.target.style = "";
              }
            }}
            leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
            appear={false}
          >
            {tagChild}
          </TweenOneGroup>
        </div>
        {inputVisible && (
          <Input
            ref={this.saveInputRef}
            type="text"
            size="small"
            style={{ width: 78 }}
            value={inputValue}
            onChange={this.handleInputChange}
            onBlur={this.handleInputConfirm}
            onPressEnter={this.handleInputConfirm}
          />
        )}
        {!inputVisible && (
          <Tag onClick={this.showInput} style={{ background: "#fff", borderStyle: "dashed" }}>
            <Icon type="plus" /> New Tag
          </Tag>
        )}
      </div>
    );
  }
}

export default EditableTagGroup;
