import * as actionType from "./News.ActionType";

const initializeState = {
  error: null,
  loading: false,
  data: null
};

export default function newsReducer(state = initializeState, action) {
  // console.log(action.type, action, "news Reducers");
  switch (action.type) {
    case actionType.GET_NEWS_REQUEST || 
      actionType.CREATE_NEWS_REQUEST ||
      actionType.REMOVE_NEWS_REQUEST ||
      actionType.GET_ONE_NEW_REQUEST ||
      actionType.SEARCH_NEWS_REQUEST ||
      actionType.UPDATE_NEWS_REQUEST:
      return { ...state, loading: true, error: null };

    case actionType.CREATE_NEWS_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case actionType.GET_NEWS_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case actionType.SEARCH_NEWS_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case actionType.UPDATE_NEWS_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case actionType.GET_ONE_NEW_SUCCESS:
      return { ...state, loading: false, data: action.payload };

    case actionType.REMOVE_NEWS_SUCCESS:
      return { ...state, loading: false, message: action.payload.message };

    case actionType.CREATE_NEWS_FAIL ||
      actionType.REMOVE_NEWS_FAIL ||
      actionType.GET_NEWS_FAIL ||
      actionType.SEARCH_NEWS_FAIL ||
      actionType.UPDATE_NEWS_FAIL:
      return { ...state, loading: false, error: action.error };
    case actionType.INITIALIZATION_NEWS_REQUEST:
      return { ...state, data: null, message: "", loading: false, error: "" };
    default:
      return state;
  }
}
