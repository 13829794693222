export const GET_ALL_CONFIG_REQUEST = 'GET_ALL_CONFIG_REQUEST'
export const GET_ALL_CONFIG_SUCCESS = 'GET_ALL_CONFIG_SUCCESS'
export const GET_ALL_CONFIG_FAILURE = 'GET_ALL_CONFIG_FAILURE'

export const EDIT_CONFIG_REQUEST = 'EDIT_CONFIG_REQUEST'
export const EDIT_CONFIG_SUCCESS = 'EDIT_CONFIG_SUCCESS'
export const EDIT_CONFIG_FAILURE = 'EDIT_CONFIG_FAILURE'

export const CREATE_CONFIG_REQUEST = 'CREATE_CONFIG_REQUEST'
export const CREATE_CONFIG_SUCCESS = 'CREATE_CONFIG_SUCCESS'
export const CREATE_CONFIG_FAILURE = 'CREATE_CONFIG_FAILURE'

export const SEARCH_CONFIG_REQUEST = 'SEARCH_CONFIG_REQUEST'
export const SEARCH_CONFIG_SUCCESS = 'SEARCH_CONFIG_SUCCESS'
export const SEARCH_CONFIG_FAILURE = 'SEARCH_CONFIG_FAILURE'
