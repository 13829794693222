import * as actionsType from './Currency.ActionType'

const initState = {
  data: null,
  currency: null,
  loading: false,
  error: null,

  updated: false,
  deleted: false,
  created: false,
}

export default function currencyReducer(state = initState, action) {
  const actions = {
    [actionsType.GET_CURRENCIES.REQUEST]: {
      ...state,
      loading: true,
      error: null
    },
    [actionsType.GET_CURRENCIES.SUCCESS]: {
      ...state,
      loading: false,
      data: action.data
    },
    [actionsType.GET_CURRENCIES.FAILURE]: {
      ...state,
      loading: false,
      error: action.error
    },
    [actionsType.GET_CURRENCY.REQUEST]: {
      ...state,
      loading: true,
      error: null
    },
    [actionsType.GET_CURRENCY.SUCCESS]: {
      ...state,
      loading: false,
      currency: action.data
    },
    [actionsType.GET_CURRENCY.FAILURE]: {
      ...state,
      loading: false,
      error: action.error
    },
    [actionsType.UPDATE_CURRENCY.REQUEST]: {
      ...state,
      loading: true,
      error: null
    },
    [actionsType.UPDATE_CURRENCY.SUCCESS]: {
      ...state,
      loading: false,
      updated: true
    },
    [actionsType.UPDATE_CURRENCY.FAILURE]: {
      ...state,
      loading: false,
      error: action.error
    },
    [actionsType.DELETE_CURRENCY.REQUEST]: {
      ...state,
      loading: true,
      error: null,
    },
    [actionsType.DELETE_CURRENCY.SUCCESS]: {
      ...state,
      loading: false,
      deleted: true,
    },
    [actionsType.DELETE_CURRENCY.FAILURE]: {
      ...state,
      loading: false,
      error: action.error,
    },
    [actionsType.CREATE_CURRENCY.REQUEST]: {
      ...state,
      loading: true,
      error: null,
    },
    [actionsType.CREATE_CURRENCY.SUCCESS]: {
      ...state,
      loading: false,
      created: true,
    },
    [actionsType.CREATE_CURRENCY.FAILURE]: {
      ...state,
      loading: false,
      error: action.error,
    },
    [actionsType.SEARCH_CURRENCY.REQUEST]: {
      ...state,
      loading: true,
      error: null
    },
    [actionsType.SEARCH_CURRENCY.SUCCESS]: {
      ...state,
      loading: false,
      data: action.data
    },
    [actionsType.SEARCH_CURRENCY.FAILURE]: {
      ...state,
      loading: false,
      error: action.error
    },
  }

  return actions[action.type] || state
}