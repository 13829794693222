import * as actionsType from './Transaction.ActionType'

const initState = {
  data: [],
  transaction: null,
  loading: false,
  error: null,
}

export default function transactionReducer(state = initState, action) {
  switch (action.type) {
    case actionsType.GET_TRANSACTIONS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      }
    case actionsType.GET_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      }
    case actionsType.GET_TRANSACTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      }

    case actionsType.SEARCH_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      }

    case actionsType.SEARCH_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      }

    case actionsType.SEARCH_TRANSACTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    default:
      return state
  }
}