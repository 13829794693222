import React from "react";
import { Form, DatePicker, Row, Button, Input, Switch } from "antd";
import { connect } from "react-redux";
import { Editor, EditorTags } from "../../../../components/Editor";
import { Loader } from "../../../../components/Loader";
import { createOneNews, getAllNews, editNews, getOneNews } from "../News.Action";
import Toast from "../Toast";
import * as ROUTES from "../../../../constants/routes";

const { TextArea } = Input;

class _CreateNews extends React.Component {
  state = {
    tags: [],
    content: "",
    loading: false
  };

  onChangeContent = content => {
    this.setState({ content });
  };

  onChangeTag = tags => {
    this.setState({ tags });
  };

  onSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields( async (errors, values) => {
      if (errors) {
        return;
      }
      const data = { ...values, tags: this.state.tags, content: this.state.content };
      if(this.props.match.params.id) {
        try {
          await this.props.editNews(this.props.match.params.id, data);
          Toast("Cập nhật thông tin thành công", "", true)
          setTimeout(() => {
            window.location.href = `${ROUTES.NEWS}`
          }, 1000)
        } catch(error) {
          Toast("Cập nhật thông tin không thành công", "", false)
        }
        
      } else {
        try {
          await this.props.createOneNews(data);
          Toast("Cập nhật thông tin thành công", "", true)
          setTimeout(() => {
            window.location.href = `${ROUTES.NEWS}`
          }, 1000)
        } catch(error) {
          Toast("Cập nhật thông tin không thành công", "", false)
        }
      }
    });
  };

  componentDidUpdate(prevProps) {
    if(!prevProps.data && this.props.data && this.props.match.params.id) {
      this.setState({
        tags: this.props.data.tags,
        content: this.props.data.content
      })
    }
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.getAllNews({ _id: this.props.match.params.id })
    }
  }

  render() {
    console.log(this.props.match.params.id)
    const { getFieldDecorator } = this.props.form;
    const { id } = this.props.match.params;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5, offset: 1 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 14 }
      }
    };

    const { loading, error } = this.props;
    const data = id ? this.props.data : { }
    // console.log("loading: ", loading, data, error, this.state);
    // if (this.props.data !== null) {
    //   this.props.form.setFieldsValue({
    //     title: data.title,
    //     content: data.content
    //   });
    //   this.setState({
    //     tags: data.tags
    //   });
    // }
    // const content = this.state.content || this.props.data[0].content;
    // const title = this.state.title || this.props.data[0].title;
    // const tags = this.state.tags || this.props.data[0].tags;
    // console.log(loading, data, this.props.match.params.id)
    if(loading || (!data && this.props.match.params.id)) return <Loader spinning />

    return (
      <>
        <Form {...formItemLayout} onSubmit={this.onSubmit}>
          <Row>
            <Form.Item label="Tiêu đề">
              {getFieldDecorator("title", {
                rules: [{ required: true, message: "..." }],
                initialValue: data && data.title
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Nội dung">
              <Editor onChange={this.onChangeContent} defaultValue={data && data.content} />
            </Form.Item>
            {/* <Form.Item label="Tác giả">
            {getFieldDecorator("author", {
              rules: [{ required: true, message: "..." }]
            })(<Input />)}
          </Form.Item> */}

            <Form.Item label="Tags">
              <EditorTags onChange={this.onChangeTag} values={this.state.tags} />
            </Form.Item>
          </Row>
          
          <Row>
            <Form.Item label="Tình trạng" >
            {getFieldDecorator('publish', {
              valuePropName: 'checked',
              initialValue: data && data.publish
            })(
              <Switch checkedChildren="Bật" unCheckedChildren="Tắt" />
            )}
          </Form.Item>
          </Row>
          <Row type="flex" justify="center">
            <Form.Item>
              <Button type="primary" htmlType="submit">
                {id === null ? "Tạo mới" : "Cập nhật"}
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.newsReducer.loading,
    data: state.newsReducer.data && state.newsReducer.data[0],
    error: state.newsReducer.error,
    message: state.newsReducer.message
  };
};

const mapDispatchToProps = {
  createOneNews,
  editNews,
  getAllNews,
  getOneNews
};

const CreateNews = Form.create({})(_CreateNews);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateNews);
