const getToken  = () => {
  let token = null
  try {
    token = localStorage.getItem("token")
  } catch(error) {
    token = undefined
  }
  return token;
}

export default getToken