import axios from 'axios';
import getToken from './getToken';

const ENDPOINT = 'https://api.giaodich24h.com';

const request = ({ endpoint, url, method, data, params, headers }) => {
  const token = getToken()
  return axios({
    baseURL: endpoint || ENDPOINT,
    url,
    method,
    params,
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
    data,
  }).then(r => r.data).catch(error => {
    throw error.response && error.response.data
  });
};

export default request;
