import React, { memo } from 'react'
import { Button, Row, Form, Input } from 'antd'
import compose from 'recompose/compose'
import './index.less'
const FormItem = Form.Item

const LoginForm = ({ handleSubmit, form, validation, loading }) => {
  const { getFieldDecorator } = form
  const handleOK = () => handleSubmit(form)

  const userNameInput = getFieldDecorator('email', {
    rules: [{ required: true }],
  })(<Input onPressEnter={handleOK} placeholder={`Email`}/>)

  const passwordInput = getFieldDecorator('password', {
    rules: [{ required: true }],
  })(<Input onPressEnter={handleOK} type="password" placeholder={`Password`}/>)

  return (
    <>
      <form>
        <FormItem hasFeedback validateStatus={validation.email.status} help={validation.email.error}>
          {userNameInput}
        </FormItem>
        <FormItem hasFeedback validateStatus={validation.password.status} help={validation.password.error}>
          {passwordInput}
        </FormItem>
        <Row>
          <Button
            type="primary"
            onClick={handleOK}
            loading={loading}
          >
            <span>Sign in</span>
          </Button>
        </Row>
      </form>
    </>
  )
}

export default compose(Form.create(), memo)(LoginForm)
